<template>
  <div class="items view">
    <app-header :title="'Lua Export'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <tabs :items="sections" v-model="section"/>
    <div class="layout mt" style="min-height: 90vh">
      <div class="flex xs12 sm4">
        <div class="tile">
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <div class="bodytext">
                <h2>Please read</h2>
                <ul class="futuristic">
                  <li>This export generates a <span class="text--teal">lua table</span> of the ingame items with the properties you select.</li>
                  <li>The <span class="text--teal">key</span> for each item is the <span class="text--teal">key</span> field. If the key field for an item is null, it will use the <span class="text--teal">id</span> field.</li>
                  <li>The <span class="text--teal">key</span> is the <span class="text--teal">unique identifier</span> that the game uses for items.</li>
                  <li>If you enable the <span class="text--teal">Export flat</span> option, the lua will be generated as a numeric indexed table and not with the key or id field.</li>
                  <li>If you enable the <span class="text--teal">Use Atlas format</span> the export will be formatted like in the very popular Atlas script to ensure a common standard.</li>
                  <li v-if="section !== 'Stellar Objects'">You can filter for specific groups by using the <span class="text--teal">group filter</span>.</li>
                  <li v-if="section !== 'Stellar Objects'">If you don't use the group filter, <span class="text--teal">all</span> items will be exported.</li>
                  <li v-if="section !== 'Stellar Objects'"><span class="text--teal">The export can take very long</span>, due to the high amount of items. This is why you can export max <span class="text--teal">{{maxFields}}</span> properties per item.</li>
                  <li v-if="section !== 'Stellar Objects'">You have to select at least <span class="text--teal">one</span> property to be able to generate the lua code.</li>
                  <li v-if="section !== 'Stellar Objects'">The property <span class="text--teal">IconInGamePath</span> gives you the relative path to the icon of the item. This is supported by the game and you <span class="text--teal">don't have to upload icons</span>.</li>
                </ul>
              </div>
            </div>
            <div class="flex xs12 mt">
              <div class="btn btn--success" :loading="loading" @click="refreshLuaCode" :disabled="!canGenerate"
                   v-slashes>
                Generate Lua
              </div>
            </div>
            <div class="flex xs12 mt" v-if="section !== 'Stellar Objects'">
              <label>Select the exported properties</label>
              <multi-select-flat
                  v-model="fields"
                  :available-items="availableFiels"
              />
            </div>
            <div class="flex xs12 mt" v-if="section !== 'Stellar Objects'">
              <text-field
                  v-model="searchTerm"
                  :label="__('common.search_term')"
                  :maxlength="50"
                  :clearable="true"
              />
            </div>
            <div class="flex xs12 mt" v-if="section !== 'Stellar Objects'">
              <label>Filter for groups</label>
              <multi-select
                  v-model="groupIds"
                  :endpoint="'/ingame/items/group'"
                  :field-title="'name'"
                  :field-icon="'icon'"
              />
            </div>
            <div class="flex xs12 mt">
              <my-switch
                  v-model="flat"
                  :label="'Export flat'"
              ></my-switch>
            </div>
            <div class="flex xs12 mt" v-if="section === 'Stellar Objects'">
              <my-switch
                  v-model="useAtlasFormat"
                  :label="'Use Atlas format'"
              ></my-switch>
            </div>
            <div class="flex xs12 mt" v-if="availableFieldsObjects.length > 0 && section !== 'Stellar Objects'">
              <label>Sort by</label>
              <select-reference
                  v-model="sortBy"
                  :force-available-items="availableFieldsObjects"
                  :field-value="'value'"
                  :field-title="'title'"
                  :nothing-selected-message="'Order by'"
                  :hide-info="true"
              />
            </div>
            <div class="flex xs12 mt" v-if="section !== 'Stellar Objects'">
              <my-switch
                  v-model="sortDesc"
                  :label="'Sort descending'"
              ></my-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="flex xs12 sm8">
        <div class="tile tile--no-padding">
          <text-area
              :id="'lua_code'"
              class="text--code"
              v-model="lua"
              :readonly="true"
              :hide-details="true"
              :full-height="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { paginationMixin } from "../../mixins/pagination";
import { pageTitleMixin } from "../../mixins/pageTitle";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    AppHeader,
  },
  data: () => ({
    loading: false,
    lua: '',
    sortBy: 'createdAt',
    sortDesc: true,
    groupIds: [],
    fields: [],
    availableFiels: [],
    maxFields: 10,
    flat: false,
    useAtlasFormat: true,
    searchTerm: null,
    sections: [
      {value: 'Items', title: 'Items', icon: 'https://cdn.hyperion-corporation.de/ingame/iconsLib/elementslib/coreunitdynamic128.png'},
      {value: 'Groups', title: 'Groups', icon: 'https://cdn.hyperion-corporation.de/ui/elements.png'},
      {value: 'Stellar Objects', title: 'Stellar Objects', icon: 'https://cdn.hyperion-corporation.de/ingame/stellar_objects/alioth.png'},
    ],
    section: 'Items',
  }),
  watch: {
    sortBy() {
      if(this.sortBy === null) {
        this.sortBy = 'createdAt';
      }
    },
    section() {
      this.groupIds = [];
      this.fields = [];
      this.refreshAvailableFiels();
      this.searchTerm = null;
      this.flat = false;
      this.useAtlasFormat = true;
    }
  },
  computed: {
    canGenerate() {
      if(this.loading) {
        return false;
      }
      if(this.section === 'Stellar Objects') {
        return true;
      }
      return this.fields.length > 0 && this.fields.length <= this.maxFields;
    },
    availableFieldsObjects() {
      return this.availableFiels.map(field => {
        return {
          value: field,
          title: field,
        }
      })
    }
  },
  methods: {
    refreshLuaCode() {
      if (!this.canGenerate) {
        return;
      }
      this.loading = true;
      let payload = {
        orderBy: `${this.sortBy} ${this.sortDesc ? 'desc' : 'asc'}`,
        orderDesc: this.sortDesc ? 1 : 0,
        groupIds: this.groupIds,
        fields: this.fields.join(','),
        flat: this.flat,
        searchQuery: this.searchTerm,
        useAtlasFormat: this.useAtlasFormat,
      }
      let endpoint = '/ingame/items/item/lua-export';
      if(this.section === 'Groups') {
        endpoint = '/ingame/items/group/lua-export';
      } else if (this.section === 'Stellar Objects') {
        endpoint = '/ingame/world/stellarobject/lua-export';
      }
      apiSecured.post(endpoint, payload).then(async (res) => {
        this.lua = res.data.lua;
        this.loading = false;
        this.showInfoNotification();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async refreshAvailableFiels() {
      let endpoint = '/ingame/items/item/properties';
      if(this.section === 'Groups') {
        endpoint = '/ingame/items/group/properties';
      } else if (this.section === 'Stellar Objects') {
        endpoint = '/ingame/world/stellarobject/properties';
      }
      await apiSecured.get(endpoint).then((res) => {
        this.availableFiels = res.data;
      }).catch((error) => {
        this.showErrorNotifications(error)
      });
    },
  },
  created() {
    this.setPageTitle('Lua Export');
    this.refreshAvailableFiels()
  }
}
</script>
